import React from "react";
import { useState, useEffect } from "react";
import "./style.css";

import Footer2 from "./Footer2";
// import UserformSurvey from "./mattress_questionnare";
import { Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { Helmet } from "react-helmet";

// import axios from "axios";

function Index(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Register";
  const navigate = useNavigate();

  const submitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("return_page", "true");
    navigate("/register/registration-form");
  };
  useEffect(() => {
    if (localStorage.getItem("return_page") === "true") {
      navigate("/register/registration-form");
    } else {
      localStorage.setItem("return_page", "false");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <div className="white-box">
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submitForm}
            >
              <h2 className="register_lower" style={{ textAlign: "center" }}>
                Ashley Furniture and Elite Rewards are proud supporters of Hope
                to Dream.
              </h2>
              <div className="row">
                <div className="col-md-12" id="text">
                  <p className="register-p">
                    Would you be interested in giving 15 minutes of your time to
                    improve your sleep? You will get a $20 Mastercard Reward
                    Card when you complete a mattress comfort test rest, and
                    we’ll also give a portion of the proceeds to Hope to Dream.
                    Just a few moments of your time can help the way you sleep.
                  </p>
                </div>
              </div>

              <input
                // onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="CONTINUE"
                className="sub-btn button"
              ></input>
            </form>
          </ul>
        </div>
      </div>

      <Footer2 />
    </>
  );
}
export default Index;
