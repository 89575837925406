import { React, useRef, useState, useEffect } from "react";

import Footer2 from "./Footer2";
import HeaderLower from "./HeaderLower";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Swal from "sweetalert2";

import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    emailid: "",
    sales_name: "",
    store_location: "",
  });

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [emplyId, setEmplyId] = useState([]);
  const [empShow, setempShow] = useState("");
  const [storeId, setStoreId] = useState([]);

  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  useEffect(() => {
    async function getStoreLocation() {
      let errors = {};

      try {
        //FIXME remove this service if not used
        const response = await RegisterService.getStoreLocation();
        let value = response.data.data;
        const results = [];
        value.map((value) => {
          return results.push({
            value: value.store_location,
            label: value.store_location,
          });
        });
        await setStoreId([...results]);

        if (response.data.status === 429 || response.data.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        if (value.includes("redirect")) {
        } else {
          errors["questionnare"] = response.data.response;
        }
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    if (localStorage.getItem("return_page") !== "true") {
      navigate("/register");
    } else {
      getStoreLocation();
    }
  }, []);

  async function getEmployee(store) {
    let errors = {};

    try {
      await setEmplyId([]);
      await setempShow("loading");
      //FIXME remove this service if not used
      const response = await RegisterService.getEmployees(store);
      let value = response.data.data;
      if (value.length) {
        const results = [];
        value.map((value) => {
          return results.push({
            label: `${value.employee_id} - ${value.fname} ${value.lname}`,
            value: value.employee_id,
          });
        });

        await setEmplyId([...results]);
        await setempShow("success");

        if (response.data.status === 429 || response.data.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        if (value.includes("redirect")) {
        } else {
          errors["questionnare"] = response.data.response;
        }
      } else {
        await setempShow("error");
      }
    } catch (err) {
      setEmplyId([]);
      await setempShow("error");

      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }
  }

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page");
    localStorage.removeItem("registerForm");
    localStorage.removeItem("isRegister");
  };

  const ref = useRef();

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "store_location" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();

    let data = {
      sales_name: fields?.sales_name,
      store_location: fields?.store_location,
      email: fields?.emailid,
      fname: fields?.name,
      lname: fields?.lname,
      captcha: captcha,
    };

    try {
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);

          Swal.fire({
            title: "Successful!",
            text: "Form Submitted!",
            type: "success",
            icon: "success",
          }).then((result) => {
            secureLocalStorage.setItem(
              "registerform",
              JSON.stringify({
                sales_name: fields.sales_name,
                store_location: fields.store_location,
                emailid: fields.emailid,
                name: fields.name,
                lname: fields.lname,
              })
            );
            localStorage.removeItem("return_page");
            localStorage.setItem("isRegister", true);
            navigate("/register/registration-thankyou");
          });

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          console.log(err);

          let errorData = {};
          errorData["sales_name"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_name &&
            err?.response?.data?.message.sales_name[0];
          errorData["store_location"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.store_location &&
            err?.response?.data?.message.store_location[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.email &&
            err?.response?.data?.message.email[0];
          errorData["name"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.fname &&
            err?.response?.data?.message.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.lname &&
            err?.response?.data?.message.lname[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.captcha &&
            err?.response?.data?.message.captcha[0];

          saveErrors(errorData);
          setTimeout(() => {
            ref?.current?.focus();
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["sales_name"] = err?.response?.data?.message.sales_name[0];
      errorData["store_location"] =
        err?.response?.data?.message.store_location[0];
      errorData["emailid"] = err?.response?.data?.message.emailid[0];
      errorData["name"] = err?.response?.data?.message.fname[0];
      errorData["lname"] = err?.response?.data?.message.lname[0];
      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];

      saveErrors(errorData);
    }
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            <h1>Register to score your free Reward Card!</h1>
            <hr />
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
              // onClick={this.popup}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    // tabIndex="1"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Last Name"
                    // tabIndex="1"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    autoFocus=""
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    // tabIndex="10"
                    ref={
                      errors.emailid && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="E-mail"
                    value={fields.emailid || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid && !errors.lname && !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Store Location <span className="red_point">*</span>
                  </label>
                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.store_location &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select Store Locations"
                    options={storeId}
                    isSearchable={true}
                    isClearable={false}
                    name="store_location"
                    ref={
                      errors.store_location &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["store_location"]: selectedOption.value,
                          };
                        });
                        getEmployee(selectedOption.value);
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["store_location"]: "",
                          };
                        });

                        setEmplyId([]);
                        fields.sales_name = "";
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.store_location &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.store_location
                      : null}
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <label className="formsub">
                    Employee ID # <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="sales_name"
                    disabled={disablility}
                    style={
                      errors.sales_name &&
                      !errors.store_location &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="sales_name"
                    placeholder="Employee ID"
                    value={fields.sales_name || ""}
                    autoFocus=""
                    ref={
                      errors.sales_name &&
                      !errors.store_location &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.sales_name &&
                    !errors.store_location &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name
                      : null}
                  </div>
                  {saleError && <div className="errorMsg">{saleError}</div>}
                </div> */}

                {/* TODO Search + Select Field */}
                {/* <div className="col-md-6">
                  <label className="formsub">
                  Employee ID # <span className="red_point">*</span>
                  </label>
                  <Select
                      style={
                      errors.sales_name &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.sales_name &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                  options={options}
                  onChange={handleSelectChange}
                  name="sales_name"
                  id="sales_name"
                  value={permissionFor}
                  placeholder="Choose a Customer"
                  className="customer-search-select"
                />

                </div> */}

                {/* TODO Slect employee_id */}
                <div className="col-md-6 ">
                  <label className="formsub">
                    Employee ID # <span className="red_point">*</span>
                  </label>
                  {emplyId.length || empShow === "" || empShow === "success" ? (
                    <Select
                      classNamePrefix="react-select"
                      className={`select_1 dropdown  ${
                        errors.sales_name &&
                        !errors.store_location &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.name
                          ? "error-select-search"
                          : ""
                      }`}
                      placeholder="Select Employee ID"
                      options={emplyId}
                      isSearchable={true}
                      isClearable={false}
                      name="sales_name"
                      ref={
                        errors.sales_name &&
                        !errors.store_location &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setFields((prevValue) => {
                            return {
                              ...prevValue,
                              ["sales_name"]: selectedOption.value,
                            };
                          });
                        } else {
                          setFields((prevValue) => {
                            return {
                              ...prevValue,
                              ["sales_name"]: "",
                            };
                          });
                        }
                      }}
                    />
                  ) : empShow === "error" ? (
                    <div className="select-skelton">
                      <span>No Employee Found </span>
                    </div>
                  ) : (
                    <div className="select-skelton placeholder-glow">
                      <span className="placeholder"></span>
                    </div>
                  )}

                  <div className="errorMsg">
                    {errors.sales_name &&
                    !errors.store_location &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name
                      : null}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="overflow-hider">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                      onChange={onCaptchaChange}
                    />
                  </div>
                  <div className="errorMsg">
                    {" "}
                    {errors.checkederrorcaptcha &&
                    !errors.sales_name &&
                    !errors.store_location &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.checkederrorcaptcha
                      : null}
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
