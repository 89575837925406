import React, { useEffect, useRef, useState } from "react";
import del from "./images/icon-6.png";
import view from "./images/icon-7.png";
import job from "./images/icon-9.png";
import sub from "./images/icon-10.png";
import edit from "./images/icon-5.png";

import Pagination from "react-js-pagination";
import useTable from "../../../hooks/useTable";
import Skeleton from "react-loading-skeleton";
import AdminListService from "../../../services/admin-list.service";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import $ from "jquery";
import { useFormik } from "formik";
import { addJobAndCards } from "../../../schema";
import moment from "moment";
import "select2";

export default function NewOsrIndex({
  osrData,
  osrLoading,
  setOsrLoading,
  getOsr,
  getCustomerWithId,
  delData,
  getAccountant,
  osr_id,
}) {
  const [customerModelView, setCustomerModelView] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [customerLoading, setCustomerLoading] = useState(false);

  const viewCustomerModel = async (gpNo, page) => {
    setCustomerModelView([]);
    setTotalPages(0);
    setCustomerLoading(true);
    try {
      const responce = await AdminListService.viewCustomerInModel(gpNo, page);

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,

            FirstName: value.FirstName,
            LastName: value.LastName,
            Address: value.Address,
            City: value.City,
            State: value.State,
            ZipCode: value.ZipCode,
            PhoneNumber: value.PhoneNumber,
            Email: value.Email,
            Denomination: value.Denomination,
            VoucherNumber: value.VoucherNumber,
            UserId: value.UserId,
          });
        });

        setCustomerModelView([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setCustomerLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCustomerModelView([]);
      setTotalResults(0);
      setTotalPages(0);
      setCustomerLoading(false);
    }
  };

  const deleteOsr = async (groupId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      allowOutsideClick: false,
      showClass: {
        popup: `del-pop  
        animate__animated
        animate__fadeInUp
        animate__faster
        
        `,
        icon: "ques-ico",
      },
      hideClass: {
        popup: `
          del-pop  
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setOsrLoading(true);

        try {
          const response = await AdminListService.deleteOsrMain(groupId);
          if (response.status === 200) {
            toast.success("Deleted Successfully!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              getOsr();
              setgropNo("");
              setSiteId("");
              setjobNumberValue("");
              setJobStatus("");
              resetForm();
            }, 1500);
          }
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOsrLoading(false);
        }
      }
    });
  };
  const submitJob = async (grpNo, jobNo) => {
    try {
      setOsrLoading(true);

      const response = await AdminListService.jobSubmit(grpNo, osr_id, jobNo);

      if (response.status === 200) {
        setOsrLoading(false);

        toast.success("Job number submitted successfully!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          resetForm();
          setjobNumberValue("");
          setJobStatus("");
          setSiteId("");
          setgropNo("");
          setExpCount(3);
          setNewCards([{ id: "card 1" }, { id: "card 2" }]);
          setAdminCards([]);
          setOldUploadCards([]);
          getOsr();
        }, 500);
        setTimeout(() => {
          getAccountant();
          $("html, body").animate(
            {
              scrollTop: $("#time-3").offset().top,
            },
            0
          );
        }, 1000);
      }
    } catch (err) {
      setOsrLoading(false);
      toast.error("Something went wrong!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const Table = ({ data }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, 20);
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped align-middle mt-3 w-100">
            <thead className="table-dark">
              <tr>
                <th>Company Name</th>
                <th>Date Submitted</th>
                <th>No. of Customers</th>
                <th>Job No.</th>

                <th>Bulk</th>
                <th width="450px">Action</th>
              </tr>
            </thead>
            <tbody>
              {slice.length ? (
                <>
                  {slice.map((el, index) => (
                    <tr
                      key={index}
                      // className={`${gropNo === el.groupNo ? "active" : ""}`}
                    >
                      <td>{el.Sitname}</td>
                      <td>{el.submited}</td>
                      <td>{el.Participants}</td>
                      <td className="text-uppercase">{el.jobNo}</td>

                      <td>{el.bulk}</td>
                      <td className="action-job">
                        <button
                          className="btn btn-outline-success"
                          data-bs-toggle="modal"
                          data-bs-target="#view_date_model"
                          title="View"
                          onClick={() => {
                            viewCustomerModel(el.groupNo, "1");
                          }}
                        >
                          <img src={view} alt="missing" /> View
                        </button>
                        <button
                          className="btn btn-outline-danger"
                          type="button"
                          onClick={() => {
                            deleteOsr(el.groupNo);
                          }}
                        >
                          <img src={del} alt="missing" /> Delete
                        </button>
                        <button
                          className="btn btn-outline-info add-job"
                          type="button"
                          onClick={() => {
                            setgropNo(el.groupNo);
                            setSiteId(el.SiteId);
                            setjobNumberValue("");
                            setJobStatus("");
                            resetForm();
                            setTimeout(() => {
                              $("#job-list").addClass("active");
                              $("html, body").animate(
                                {
                                  scrollTop: $("#job-list").offset().top,
                                },
                                0
                              );
                            }, 500);
                          }}
                        >
                          <img src={job} alt="missing" /> Add Job No.{" "}
                        </button>
                        <button
                          className="btn btn-outline-warning sub-job"
                          type="button"
                          disabled={el.jobNo === "n/a" ? true : false}
                          onClick={() => {
                            submitJob(el.groupNo, el.jobNo);
                          }}
                        >
                          <img src={sub} alt="missing" /> Submit
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="7" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {data.length && data.length > 20 ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={20}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  // const deleteModelCustomer = async (cusId, gpNo) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "you want to delete record",
  //     icon: "question",
  //     iconColor: "#e04f5d",
  //     showCancelButton: true,
  //     confirmButtonColor: "#dc3545",
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "Close",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       setOsrLoading(true);

  //       try {
  //         const response = await AdminListService.deleteSingleOsrCustomer(
  //           cusId
  //         );
  //         if (response.status === 200) {
  //           setOsrLoading(false);
  //           toast.success("Deleted Successfully!", {
  //             position: "top-center",
  //             autoClose: 1500,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "colored",
  //           });

  //           setTimeout(() => {
  //             getOsr();
  //             viewCustomerModel(gpNo, currentPage);
  //           }, 1500);
  //         }
  //       } catch (err) {
  //         toast.error("Something went wrong!", {
  //           position: "top-center",
  //           autoClose: 1500,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //         setOsrLoading(false);
  //       }
  //     }
  //   });
  // };

  const CustomerModelTable = ({ data }) => {
    return (
      <>
        <div className="table-responsive ">
          <table className="table table-striped align-middle w-100">
            <thead className="table-dark">
              <tr>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Address </th>
                <th>City </th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Denomination</th>
                <th>Voucher No.</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                <>
                  {data.map((el, index) => (
                    <tr key={index}>
                      <td>{el.FirstName}</td>
                      <td>{el.LastName}</td>
                      <td>{el.Address}</td>
                      <td>{el.City}</td>
                      <td>{el.State}</td>
                      <td>{el.ZipCode}</td>
                      <td>{el.PhoneNumber}</td>
                      <td>{el.Email}</td>
                      <td>{el.Denomination}</td>
                      <td>{el.VoucherNumber}</td>
                      <td>
                        {/* <td> */}
                        <button
                          className="btn p-0 btn-sm border-0"
                          type="button"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            getCustomerWithId(el.UserId, "osr", el.groupNo);
                          }}
                        >
                          <img src={edit} className="edit" alt="missing" />
                        </button>
                        <button
                          className="btn btn-sm ms-2 border-0 p-0"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            delData(el.UserId, "osr");
                          }}
                        >
                          <img src={del} className="deleted" alt="missing" />
                        </button>
                        {/* </td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <button
                            className="text-warning open-model-btn"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              getCustomerWithId(el.UserId, "osr");
                            }}
                          >
                            <img src={edit} className="edit" alt="missing" />
                          </button>
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="text-danger open-model-btn"
                            onClick={() => {
                              delData(el.UserId, "osr");
                            }}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>
                        </div> */}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="11" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              className="justify-content-center"
              onChange={(e) => {
                viewCustomerModel(data[0]?.groupNo, e);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  // assign job number functions

  const [adminCards, setAdminCards] = useState([]);
  const [jobNumberValue, setjobNumberValue] = useState("");
  const [oldUploadCards, setOldUploadCards] = useState([]);
  const [gropNo, setgropNo] = useState("");
  const [siteId, setSiteId] = useState("");
  const [jobStatus, setJobStatus] = useState("");

  const [jobLoader, setJobLoader] = useState(false);
  const [adminCardsLoader, setAdminCardsLoader] = useState(false);
  const [expCount, setExpCount] = useState(3);
  const [newCards, setNewCards] = useState([
    { id: "card 1" },
    { id: "card 2" },
  ]);

  const addCards = async () => {
    if (expCount <= 12) {
      setNewCards([
        ...newCards,
        {
          id: "card " + expCount,
        },
        {
          id: "card " + (expCount + 1),
        },
      ]);
      setExpCount(expCount + 2);
    } else {
      toast.error("Maximum 12 card allowed!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const getCardsAdmin = async () => {
    if (!adminCards.length) {
      setAdminCardsLoader(true);
      try {
        const responce = await AdminListService.getAdminCards();

        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            value: value.CardId,
            label: value.CardName,
          });
        });
        await setAdminCards([...results]);
        setAdminCardsLoader(false);
      } catch (err) {
        console.log(err);
        await setAdminCards([]);

        setAdminCardsLoader(false);
      }
    }
  };

  const getLastUploadedCards = async () => {
    setAdminCardsLoader(true);

    try {
      const response = await AdminListService.showLastUploadCards(siteId);

      if (response.status === 200) {
        const res = response.data.data;
        const results = [];
        await res.map((value) => {
          return results.push({
            CardImage: value,
          });
        });
        await setOldUploadCards([...results]);

        setAdminCardsLoader(false);
        setTimeout(() => {
          $(".card-selection").show();
          $(".email-sent").show();
          $(".card-selection-new").hide();
        }, 100);
      }
    } catch (err) {
      setAdminCardsLoader(false);
      if (err.response?.status === 404) {
        // $("#newJob").trigger(clickEvent);
        await setOldUploadCards([]);
      }
    }
  };

  const getLastUploadedCardsForJob = async (job) => {
    setAdminCardsLoader(true);

    try {
      const response = await AdminListService.showLastUploadCardsForJob(job);

      if (response.status === 200) {
        const res = response.data.data;
        const results = [];
        await res.map((value) => {
          return results.push({
            CardImage: value,
          });
        });
        await setOldUploadCards([...results]);

        setAdminCardsLoader(false);
        setTimeout(() => {
          $(".card-selection").show();
          $(".email-sent").show();
          $(".card-selection-new").hide();
        }, 100);
      }
    } catch (err) {
      setAdminCardsLoader(false);

      await setOldUploadCards([]);
    }
  };
  let searchTimer;
  const varifyJobNumber = async (jobNo) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      resetForm();
      setExpCount(3);
      setNewCards([{ id: "card 1" }, { id: "card 2" }]);
      if (jobNo.length >= "5" && jobNo.length <= "6") {
        setJobLoader(true);
        await setjobNumberValue(jobNo);
        try {
          const response = await AdminListService.checkJobNumber(jobNo);

          if (response.status === 200) {
            setJobLoader(false);
            setJobStatus("newJobNo");
            getLastUploadedCards();
          }
        } catch (err) {
          setJobLoader(false);
          if (err.response?.status === 409) {
            getLastUploadedCardsForJob(jobNo);
            setJobStatus("oldJobNo");
          }
        }
      } else {
        setJobStatus("");
        setjobNumberValue("");
        toast.error("Please enter 5 or 6 digit number", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }, 1000);
  };

  const changeStartDate = async (e) => {
    var date = new Date(e.target.value);
    const sixMonthsLater = new Date(date.getTime());
    sixMonthsLater.setMonth(date.getMonth() + 6);
    const formattedEndDate = sixMonthsLater
      .toLocaleDateString("en-US")
      .split("/")
      .join("/");
    setFieldValue("redEdate", moment(formattedEndDate).format("YYYY-MM-DD"));
  };

  const addFinalJobNo = async (values, action) => {
    try {
      setJobLoader(true);

      const response = await AdminListService.allFinalAddJob(
        values,
        gropNo,
        osr_id,
        jobNumberValue,
        jobStatus,
        siteId
      );

      if (response.status === 200) {
        setJobLoader(false);

        $("html, body").animate(
          {
            scrollTop: $("#time-2").offset().top,
          },
          0
        );

        action.resetForm();
        setjobNumberValue("");
        setJobStatus("");
        setSiteId("");
        setgropNo("");
        setExpCount(3);
        setNewCards([{ id: "card 1" }, { id: "card 2" }]);
        setAdminCards([]);
        setOldUploadCards([]);

        toast.success("Job number added successfully!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          getOsr();
        }, 1000);
      }
    } catch (err) {
      setJobLoader(false);
      toast.error("Some thing went wrong!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const cardSelectInitialValues = {
    card1: "Select Card 1",
    card2: "Select Card 2",
    card3: "Select Card 3",
    card4: "Select Card 4",
    card5: "Select Card 5",
    card6: "Select Card 6",
    card7: "Select Card 7",
    card8: "Select Card 8",
    card9: "Select Card 9",
    card10: "Select Card 10",
    card11: "Select Card 11",
    card12: "Select Card 12",
    redSdate: "",
    redEdate: "",
    emailStatus: "",
    lastCard: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    resetForm,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: cardSelectInitialValues,
    validationSchema: addJobAndCards,
    onSubmit: (values, action) => {
      addFinalJobNo(values, action);
    },
  });

  const Select2Field = ({ name, label, options, value }) => {
    const selectRef = useRef();

    useEffect(() => {
      const $select = $(selectRef.current);

      $select.select2();

      $select.on("change", (event) => {
        const selectedValue = $(event.target).val();
        setFieldValue(name, selectedValue);
      });

      return () => {
        $select.select2("destroy");
        $select.off("change");
      };
    }, []);

    return (
      <>
        <label className="form-label text-capitalize">{label}</label>
        <select
          ref={selectRef}
          name={name}
          className="form-select"
          value={value || ""}
        >
          <option value="">Select {label}</option>
          {options.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <div className="uk-timeline-content">
      {/* step 2  */}
      <div className="card">
        <div className="card-header">Welcome OSR</div>
        {osrLoading ? (
          <Skeleton count={5} height="48px" />
        ) : (
          <Table data={osrData} />
        )}

        {/* <table className="table table-striped align-middle mt-3 w-100">
          <thead className="table-dark">
            <tr>
              <th>Company Name</th>
              <th>Date Submitted</th>
              <th>No. of Customers</th>
              <th>Job No.</th>
              <th>Bulk</th>
              <th width="450px">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ashleyrewards</td>
              <td>22-02-2024</td>
              <td>22</td>
              <td>2245</td>
              <td>No</td>
              <td className="action-job">
                <button
                  className="btn btn-outline-success"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  type="button"
                >
                  <img src={view} alt="missing" /> View
                </button>
                <button className="btn btn-outline-danger">
                  <img src={del} alt="missing" /> Delete
                </button>
                <button className="btn btn-outline-info add-job" type="button">
                  <img src={job} alt="missing" /> Add Job No
                </button>
                <button
                  className="btn btn-outline-warning complete-2"
                  type="button"
                >
                  <img src={sub} alt="missing" /> Submit
                </button>
              </td>
            </tr>
            <tr>
              <td>ashleyrewards</td>
              <td>22-02-2024</td>
              <td>22</td>
              <td>2245</td>
              <td>No</td>
              <td className="action-job">
                <button
                  className="btn btn-outline-success"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  type="button"
                >
                  <img src={view} alt="missing" /> View
                </button>
                <button className="btn btn-outline-danger">
                  <img src={del} alt="missing" /> Delete
                </button>
                <button className="btn btn-outline-info add-job" type="button">
                  <img src={job} alt="missing" /> Add Job No
                </button>
                <button
                  className="btn btn-outline-warning complete-2"
                  type="button"
                >
                  <img src={sub} alt="missing" /> Submit
                </button>
              </td>
            </tr>
            <tr>
              <td>ashleyrewards</td>
              <td>22-02-2024</td>
              <td>22</td>
              <td>2245</td>
              <td>No</td>
              <td className="action-job">
                <button
                  className="btn btn-outline-success"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  type="button"
                >
                  <img src={view} alt="missing" /> View
                </button>
                <button className="btn btn-outline-danger">
                  <img src={del} alt="missing" /> Delete
                </button>
                <button className="btn btn-outline-info add-job" type="button">
                  <img src={job} alt="missing" /> Add Job No
                </button>
                <button
                  className="btn btn-outline-warning complete-2"
                  type="button"
                >
                  <img src={sub} alt="missing" /> Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>

      {gropNo !== "" && siteId !== "" ? (
        <form className="row mb-5" id="job-list" onSubmit={handleSubmit}>
          <div className="col-lg-7">
            <div className="card mt-3">
              <div className="col-lg-12  email-sent">
                <div
                  className="question-heading w-100"
                  // className={`question-heading ${
                  //   jobNumberValue === "" ? "w-100" : ""
                  // }`}
                >
                  Assign Job No.
                </div>
                {/* {jobNumberValue !== "" ? (
                  jobLoader ? (
                    <div
                      className="btn-group yes-no"
                      style={{ marginTop: "-3px" }}
                    >
                      <Skeleton count={1} height="48px" width="175px" />
                    </div>
                  ) : (
                    <div
                      className="btn-group yes-no"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button type="submit" className="btn btn-primary active">
                        Submit Job Number
                      </button>
                    </div>
                  )
                ) : null} */}
                <div className="clearfix"></div>
              </div>

              <div className="card-body">
                <div className="row g-3 mb-2">
                  <div className="col-md-12 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">
                        Job Number <span className="gl-form-asterisk"></span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Job Number"
                        onChange={(e) => {
                          varifyJobNumber(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">
                        Start Date <span className="gl-form-asterisk"></span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Start Date"
                        name="redSdate"
                        value={values.redSdate || ""}
                        onChange={(e) => {
                          handleChange(e);
                          changeStartDate(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.redSdate && touched.redSdate ? (
                        <span className="text-danger">{errors.redSdate}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-label">
                        End Date <span className="gl-form-asterisk"></span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="End Date"
                        name="redEdate"
                        value={values.redEdate || ""}
                        disabled={true}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.redEdate && touched.redEdate ? (
                        <span className="text-danger">{errors.redEdate}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                {jobNumberValue !== "" ? (
                  jobLoader ? (
                    <Skeleton count={1} height="50px" />
                  ) : (
                    <div className="row">
                      <div className="col-lg-9 pe-lg-0 email-sent">
                        <div className="question-heading">
                          Q: Do you want to send email?
                        </div>
                        <div
                          className="btn-group yes-no"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn btn-primary ${
                              values?.emailStatus === "yes" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="emailStatus"
                              value="yes"
                              checked={values?.emailStatus === "yes"}
                              onChange={handleChange}
                            />
                            Yes
                          </button>
                          <button
                            type="button"
                            className={`btn btn-primary ${
                              values?.emailStatus === "no" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="emailStatus"
                              value="no"
                              checked={values?.emailStatus === "no"}
                              onChange={handleChange}
                            />
                            No
                          </button>
                        </div>
                        <div className="clearfix"></div>
                        {errors.emailStatus && touched.emailStatus ? (
                          <span className="text-danger d-block text-end mt-2">
                            {errors.emailStatus}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-lg-3 d-none d-lg-block">
                        <div className="yes-no">
                          <button
                            type="submit"
                            className="btn btn-primary active mt-0"
                          >
                            Submit Job No.
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
          {jobNumberValue !== "" ? (
            jobLoader ? (
              <div className="col-lg-5 mt-lg-2 pt-lg-1">
                <Skeleton count={1} height="48px" />
                <div className="mt-3"></div>
                <Skeleton count={1} height="150px" />
              </div>
            ) : (
              <div className="col-lg-5">
                <div className="mt-lg-3 card-save">
                  <div className="question-heading question-heading-last-cards">
                    Q: Do you want to upload last cards?
                  </div>
                  <div
                    className="btn-group yes-no"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className={`btn btn-primary ${
                        values?.lastCard === "yes" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="lastCard"
                        value="yes"
                        checked={values?.lastCard === "yes"}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("card1", "Select Card 1");
                        }}
                      />
                      Yes
                    </button>
                    <button
                      type="button"
                      className={`btn btn-primary ${
                        values?.lastCard === "no" ? "active" : ""
                      }`}
                      onClick={() => {
                        if (values?.lastCard !== "no") {
                          getCardsAdmin();
                        }
                      }}
                    >
                      <input
                        type="radio"
                        value="no"
                        name="lastCard"
                        checked={values?.lastCard === "no"}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("card1", "");
                        }}
                      />
                      No
                    </button>
                  </div>
                  <div className="clearfix"></div>
                  {errors.lastCard && touched.lastCard ? (
                    <span className="text-danger d-block text-end mt-2">
                      {errors.lastCard}
                    </span>
                  ) : null}
                </div>
                {adminCardsLoader ? (
                  <>
                    <div className="mt-3"></div>
                    <Skeleton count={1} height="150px" />
                  </>
                ) : oldUploadCards.length && values?.lastCard !== "no" ? (
                  <div className="card-body mt-lg-3 card-selection">
                    <div className="row">
                      {oldUploadCards.map((el, index) => (
                        <div className="col-lg-6 mb-lg-3" key={index}>
                          <div className="cards-img">
                            <div className="pirds pirds2">
                              <span className="rdcrdtop">
                                <table border="0">
                                  <tbody>
                                    <tr>
                                      <td>{el.CardImage}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </span>
                              <span className="rdcrd">REWARD CARD</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : adminCards.length ? (
                  <div className=" card-selection-new">
                    <div className="row list-items">
                      {newCards.length
                        ? newCards.map((epenses, index) => (
                            <div className="col-md-6 mt-3">
                              {/* <div className="form-group">
                                <label className="form-label">
                                  {epenses.id}
                                </label>
                                <select
                                  name={epenses.id.replace(/\s/g, "")}
                                  className="form-select"
                                  value={
                                    values[epenses.id.replace(/\s/g, "")] || ""
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select {epenses.id}</option>
                                  {adminCards.map((val, index) => (
                                    <option key={index} value={val.value}>
                                      {val.label}
                                    </option>
                                  ))}
                                </select>
                                {errors[epenses.id.replace(/\s/g, "")] &&
                                touched[epenses.id.replace(/\s/g, "")] ? (
                                  <span className="text-danger">
                                    {errors[epenses.id.replace(/\s/g, "")]}
                                  </span>
                                ) : null}
                              </div> */}
                              <div className="form-group select2-plug">
                                <Select2Field
                                  name={epenses.id.replace(/\s/g, "")}
                                  label={epenses.id}
                                  options={adminCards}
                                  value={
                                    values[epenses.id.replace(/\s/g, "")] || ""
                                  }
                                />
                                {errors[epenses.id.replace(/\s/g, "")] &&
                                touched[epenses.id.replace(/\s/g, "")] ? (
                                  <span className="text-danger">
                                    {errors[epenses.id.replace(/\s/g, "")]}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                    {expCount <= 12 ? (
                      <div className="row">
                        <div className="col-md-4 col-lg-4 ms-auto text-end">
                          <button
                            className="btn btn-primary reset-btn p-0 w-100 mt-2"
                            type="button"
                            onClick={addCards}
                          >
                            Add Cards
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="alert alert-danger  p-2 rounded-1 mt-3">
                    <strong className="fw-bold">No card found!</strong>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-4  ms-auto text-end d-block d-lg-none">
                    <button
                      type="submit"
                      className={`btn btn-primary  p-0 w-100 mt-2 ${
                        values?.lastCard === "no" ? "form-btn" : "reset-btn"
                      }`}
                    >
                      Submit Job No.
                    </button>
                  </div>
                </div>
              </div>
            )
          ) : null}

          <div className="clearfix"></div>
        </form>
      ) : null}

      <div
        className="modal fade"
        id="view_date_model"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {customerLoading ? (
                <Skeleton count={3} height="48px" />
              ) : (
                <CustomerModelTable data={customerModelView} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
