import React from "react";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import "./LanguageTranslate.css";
const LanguageTranslate = () => {
  let scriptAppended = false;
  let addScript;

  useEffect(() => {
    if (!scriptAppended) {
      const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,es",
            autoDisplay: false,
          },
          "google_translate_element"
        );
      };
      addScript = document.createElement("script");
      addScript.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      addScript.async = true;
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
      scriptAppended = true;
    }

    secureLocalStorage.setItem("panel", "user");
    return () => {
      if (addScript && addScript.parentNode === document.body) {
        document.body.removeChild(addScript);
      }
    };
  }, []);
  return (
    <>
      <div className="translation-box">
        <div id="google_translate_element"></div>
      </div>
    </>
  );
};
export default LanguageTranslate;
